// dayjs('2019-01-25').format('DD/MM/YYYY')
import dayjs from 'dayjs'
console.log('----', dayjs)
export default {
  timeFormat (Value) {
    const str = dayjs(1672280550 * 1000).format('YYYY-MM-DD HH:mm:ss')
    console.log('--------', str)
    return str
  }
}
