import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import golobalComponents from './utils/golobalComponent'
import filters from './utils/filters'
import oleayhead from 'oleayhead'
import '../node_modules/oleayhead/oleayhead.css'
console.log('---------', oleayhead)
Object.keys(golobalComponents).forEach(item => {
  Vue.component(item, golobalComponents[item])
})
Object.keys(filters).forEach(item => {
  Vue.filter(item, filters[item])
})

Vue.use(ElementUI)
Vue.config.productionTip = false

Vue.use(oleayhead)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
