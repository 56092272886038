import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/student/sclass'
  },
  {
    path: '/student',
    name: 'Student',
    redirect: '/student/sclass',
    component: () => import('../views/students/index.vue'),
    children: [
      {
        path: '/student/sclass',
        component: () => import('../views/students/sclass.vue')
      },
      {
        path: '/student/hometown',
        component: () => import('../views/students/hometown.vue')
      }
    ]
  }
  // {
  //   path: '/student/sclass',
  //   name: 'Sclass',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import('../views/students/sclass.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
